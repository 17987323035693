import logo from './logo.svg';
import './App.scss';
import Navigation from './Navigation/Navigation';
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from 'react';
function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true,     // Whether animation should happen only once
      mirror: false,  // Elements animate out while scrolling past them
    });
  }, []);

  return (
    <Navigation/>
  );
}

export default App;
